import * as React from "react"
import { Container } from "react-bootstrap"
import RingLoader from "react-spinners/RingLoader";

const Detail = ({data}) => {
    console.log("Data: ", data);
    window.location.replace('/');
    return (
        <Container className="loader">
            <RingLoader color="#2a4379" />
        </Container>
    )
}

export default Detail;